
// Libraries
import * as React from 'react'
import { Link }  from 'gatsby'
import Classnames from 'classnames'

class Button extends React.Component {
	render() {
		let classes = Classnames([
			'button',
			{
				'button--hollow': this.props.hollow,
			},
			{
				'button--color-endeavour': this.props.colorEndeavour,
			},
			{
				'button--color-pomegranate': this.props.colorPomegranate,
			},
			{
				'button--color-white': this.props.colorWhite,
			},
			{
				'button--icon-only': this.props.iconOnly,
			},
			{
				'button--block': this.props.block,
			},
			{
				'button--xs-block': this.props.xsBlock,
			},
			{
				'button--loading': this.props.isLoading
			},
			{
				'button--fixed': this.props.fixedButton
			},
			{
				'button--mob-full-width': this.props.mobFullWidth
			},
			{
				'button--no-dots': this.props.noDots
			},
			{
				'back-button': this.props.backButton
			},
			{
				'button--success': this.props.isSuccess
			},
			{
				'button--active': this.props.isActive
			},
			{
				'button--danger': this.props.danger
			}
		])

		const buttonText = <span className="button__text">
			<svg className="button__dots" viewBox="0 0 26.11 5.21" xmlns="http://www.w3.org/2000/svg"><g><circle cx="2.5" cy="2.71" r="2.5"/><circle cx="9.56" cy="2.65" r="2.5"/><circle
				cx="16.56" cy="2.56" r="2.5"/><circle cx="23.61" cy="2.5" r="2.5"/></g></svg>
			{this.props.children}
		</span>

		const {to, target, type, onClick, ...buttonProps} = this.props

		return (
			<>
				{to && target === '_blank'
					? <a href={to} target={target} className={classes} {...buttonProps}>
						{buttonText}</a>
					: to
						? <Link to={to} className={classes} {...buttonProps}>
							{buttonText}</Link>
						: <button type={type} onClick={onClick} className={classes} {...buttonProps}>
							{buttonText}</button>
				}
			</>
		)
	}
}

export default Button
