import * as React from 'react'

// Services
import AuthService from '../services/auth'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import DuoSection from '../components/duoSection'
import Button from '../components/button'

class ProfileBanned extends React.Component {
	render() {
		return (
			<Layout className="profile-banned nav-blue-half no-footer">
				<Seo title="Profile Banned" />
				<DuoSection success ProfileBanned>
					<div>
						<div className="content-wrap">
							<h1>Profile Banned</h1>
							<Button onClick={() => this.handleLogOut()} hollow xsBlock colorWhite>
								Log out
							</Button>
						</div>
					</div>
					<div></div>
				</DuoSection>
			</Layout>
		)
	}

	handleLogOut = () => {
		const authService = new AuthService()

		return authService.logOut()
	}
}

export default ProfileBanned
